




















































import Vue from 'vue';

export default Vue.extend({
  name: 'NotificationSettingsCard',
  props: {
    color: {
      type: String,
      default: 'background',
    },
    data: {
      type: Object,
    },
    group: {
      type: String,
    },
  },
  methods: {
    async updateNotificationSettings(item) {
      try {
        const settings = {
          notifications: {
            [this.group]: {
              ...this.data, ...{ [item]: !this.data[item] },
            },
          },
        };

        await this.$store.dispatch('user/updateNotificationSettings', { settings });

        const message = this.data[item]
          ? this.$t('profile.views.notifications.subscribed', { type: item })
          : this.$t('profile.views.notifications.unsubscribed', { type: item });
        this.$notify.success(message);
      } catch (error) {
        this.$notify.error(error);
      }
    },
    icon(item) {
      const mappings = {
        push: 'mdi-bell-ring',
        email: 'mdi-email-outline',
        phone: 'mdi-phone-outline',
      };
      return mappings[item];
    },
  },
});
